import React from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// components
import PageTitle from "../../../components/PageTitle";
import Widget from "../../../components/Widget";
import Table from "../../dashboard/components/Table/Table";
import $ from "jquery";
// data
import mock from "../../dashboard/mock";
import firebase from "firebase";
import { withRouter } from "react-router-dom";
import axios from 'axios';
class RestaurantList extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new post',
    description: 'About description',
  };

  constructor() {
    super();
    this.state = { dataSet: [] };
  }


  componentDidMount(){

  	firebase.firestore().collection('restaurant')
            .get()
            .then( snapshot => {
                const dataSet = []
                snapshot.forEach(doc => {
                dataSet.push([doc.data().name, doc.data().restaurant_email, doc.data().address,doc.id,doc.id,doc.data().restaurant_email,doc.id,[doc.id,doc.data().status]]);
      
                });
                  return dataSet;
              })
             .then(userList => { 
             	this.setState({dataSet:userList});
            })
        .catch(error => console.log(error))
  }
 

restaurantdelete(id) {
      if (window.confirm("Delete the item?")) {
        firebase.firestore().collection("restaurant").doc(id).delete();
        var body={ids:id};
        axios.post('https://digittrix-staging.live/webci/foodapi/deleteRestu',body, {
        headers: {
        Accept: 'multipart/form-data',
        "Content-Type": 'multipart/form-data',
        },
        });
        var jobskill_query = firebase.firestore().collection('menus').where('resturent_id','==',id);
jobskill_query.get().then(function(querySnapshot) {
  querySnapshot.forEach(function(doc) {
    doc.ref.delete();
  });
})
  var jobskill_query2 = firebase.firestore().collection('Favourites').where('resturent_id','==',id);
  jobskill_query2.get().then(function(querySnapshot) {
    querySnapshot.forEach(function(doc) {
      doc.ref.delete();
    });
});
      this.componentDidMount();
    }
    }
    restaurantdisable(id,v,h) {
      if (window.confirm("Are You Sure?")) {
       firebase.firestore().collection("restaurant").doc(id).update({
       status: v
       });
       if(v==0)
       {
        var datas=2;
       }
       else
       {
         var datas=1;
       }
       var body={status:datas,uid:id};
        axios.post('https://digittrix-staging.live/webci/foodapi/updateRestuStatus',body, {
        headers: {
        Accept: 'multipart/form-data',
        "Content-Type": 'multipart/form-data',
        },
        })
        .then((res) => {
          this.componentDidMount();
        });
  
    }
    }
    sendmail(e)
    {
      firebase.auth().sendPasswordResetEmail(e.currentTarget.id).then((res) => {
     alert('Email send Successfully on '+e.currentTarget.id);
      }).catch((error) => {
    console.log('err',error);
      });
  
    }

  render() {
    return(
      <>
      <PageTitle title="Restaurants" />
      <div className="pull-right mt-n-xs">
                <Link to="/app/restaurants/new" className="btn btn-primary btn-inverse">
                  Create New Restaurants
                </Link>
              </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            title=""
            data={this.state.dataSet}
            columns={["Restaurants", "Email", "Address", {
              label: "Menulist",
              options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                          <Link to={"/app/restaurants/menu/" + value} className='btn btn-primary'> Menu </Link>
                      )
                  }
              }
              }, {
              label: "Edit",
              options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                          <Link to={"/app/restaurants/edit/" + value} className='btn btn-primary'> Edit </Link>
                      )
                  }
              }
              },
              {
                label: "Rest Password",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                          <button className='btn btn-success'  id={value} onClick={this.sendmail.bind(this)} > Send Mail </button>
                        )
                    }
                }
                },
              
              {
              label: "Delete",
              options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                      <button className='btn btn-danger' onClick={() => this.restaurantdelete(value)}>Delete</button>
                      )
                  }
              }
          }, {
              label: "Action",
              options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                      <div>
                      {(() => {
            if (value[1]==1) {
              
              return (
              <button className='btn btn-danger' onClick={() => this.restaurantdisable(value[0],0,value[1])}>Disable</button>)
                    }else{
                     return (<button className='btn btn-success' onClick={() => this.restaurantdisable(value[0],1,value[1])}>Enable</button>)
                  }
                  })()}
                     </div>)
                  }
              }
          }
          ]}
           
          />
        </Grid>
      </Grid>
    </>
  );
  }
}
export default withRouter(RestaurantList);


