import React from 'react';
import { Switch, Route, withRouter } from 'react-router';

import IdealList from './list/IdealList';
import IdealNew from './new/IdealNew';
import IdealEdit from './edit/IdealEdit';

class Ideal extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/app/ideal" exact component={IdealList} />
        <Route path="/app/ideal/new" exact component={IdealNew} />
        <Route path="/app/ideal/edit/:id" exact component={IdealEdit} />
      </Switch>
    );
  }
}

export default withRouter(Ideal);
