import React from 'react';
import { Switch, Route, withRouter } from 'react-router';

import LocationsList from './list/LocationsList';
import LocationsNew from './new/LocationsNew';
import LocationsEdit from './edit/LocationsEdit';

class Locations extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/app/locations" exact component={LocationsList} />
        <Route path="/app/locations/new" exact component={LocationsNew} />
        <Route path="/app/locations/edit/:id" exact component={LocationsEdit} />
      </Switch>
    );
  }
}

export default withRouter(Locations);
