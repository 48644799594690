import React from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

import { Link } from 'react-router-dom';
// components
import PageTitle from "../../../components/PageTitle";
import Widget from "../../../components/Widget";
import Table from "../../dashboard/components/Table/Table";

// data
import mock from "../../dashboard/mock";
import firebase from "firebase";
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

const db = firebase.firestore();

class LocationsList extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new Locations',
    description: 'About description',
  };

  constructor() {
    super();
    this.state = { dataSet: [] };
  }
  componentDidMount(){
    db.collection('locations')
            .get()
            .then( snapshot => {
                const dataSet = []
                snapshot.forEach(doc => {
                    dataSet.push([doc.data().name,doc.data().lat,doc.data().long,doc.id]);
                });
                  return dataSet;
              })
             .then(userList => { 
              this.setState({dataSet:userList});
            })
        .catch(error => console.log(error))
  }
  menudelete(id) {
      if (window.confirm("Delete the item?")) {
      db.collection("locations").doc(id).delete();
      this.componentDidMount();
    }
    }

  render() {
  return (
    <>
      <PageTitle title="Locations" />
      <div className="pull-right mt-n-xs">
                <Link to="/app/locations/new" className="btn btn-primary btn-inverse">
                  Create New Locaions
                </Link>
              </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            title=""
            data={this.state.dataSet}
            columns={["Location Name","Latitude","Longitude", {
              label: "Edit",
              options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                          <Link to={"/app/locations/edit/" + value} className='btn btn-primary'> Edit </Link>
                      )
                  }
              }
          }]}
            options={{
              filterType: "checkbox",
            }}
          />
        </Grid>
        {/*<Grid item xs={12}>
          <Widget title="Material-UI Table" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <Table data={mock.table} />
          </Widget>
        </Grid>*/}
      </Grid>
    </>
  );
}
}
export default withRouter(LocationsList);



