import React from 'react';
import { Switch, Route, withRouter } from 'react-router';
import contact from './list/contact';
class Contact extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/app/contact" exact component={contact} />
      </Switch>
    );
  }
}
export default withRouter(Contact);
