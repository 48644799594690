import React from 'react';
import { Switch, Route, withRouter } from 'react-router';

import AllergenList from './list/AllergenList';
import AllergenNew from './new/AllergenNew';
import AllergenEdit from './edit/AllergenEdit';

class Allergen extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/app/allergen" exact component={AllergenList} />
        <Route path="/app/allergen/new" exact component={AllergenNew} />
        <Route path="/app/allergen/edit/:id" exact component={AllergenEdit} />
      </Switch>
    );
  }
}

export default withRouter(Allergen);
