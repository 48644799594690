import React from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

import { Link } from 'react-router-dom';
// components
import PageTitle from "../../../components/PageTitle";
import Widget from "../../../components/Widget";
import Table from "../../dashboard/components/Table/Table";

// data
import mock from "../../dashboard/mock";
import firebase from "firebase";
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import moment from 'moment';

const db = firebase.firestore();

class OrderList extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new post',
    description: 'About description',
  };

  constructor() {
    super();
    this.state = { dataSet: [] };
  }
  componentDidMount(){
    db.collection('orders')
            .get()
            .then( snapshot => {
                const dataSet = []
                snapshot.forEach(doc => {
                    dataSet.push([doc.id, moment(new Date(doc.data().current_date)).format('DD/MM/YYYY'), moment(new Date(doc.data().current_date)).format('DD/MM/YYYY'), doc.data().restu_name,
                    doc.data().restu_city, doc.data().user_name, doc.data().phone, doc.data().email, doc.data().street+" "+doc.data().city+" "+doc.data().state+" "+doc.data().country,
                    "€ "+Number(doc.data().grandtotal).toFixed(2).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    "€ "+Number(doc.data().commition).toFixed(2).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                     "€ "+Number(doc.data().discount).toFixed(2).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                      "€ "+Number(doc.data().shipping).toFixed(2).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),doc.data().payment,"Pending",doc.id]);
                });
                  return dataSet;
              })
             .then(userList => { 
              this.setState({dataSet:userList});
            })
        .catch(error => console.log(error))
  }
  render() {
  return (
    <>
      <PageTitle title="Orders" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            title=""
            data={this.state.dataSet}
            columns={["Order Id", "Data Ordine", "Data/Ora Delivery","Restaurant Name", "Restaurant City","Customer Name","Customer Phone","Customer Email","Custome Address","Grandtotal","Earning","Discount","Shipping","Payment Status","Delivery Status", {
              label: "See Dish",
              options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                          <Link to={"/app/orders/edit/" + value} className='btn btn-primary'> View </Link>
                      )
                  }
              }
          }
          ]}
            options={{
              filterType: "checkbox",
            }}
          />
        </Grid>
        {/*<Grid item xs={12}>
          <Widget title="Material-UI Table" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <Table data={mock.table} />
          </Widget>
        </Grid>*/}
      </Grid>
    </>
  );
}
}
export default withRouter(OrderList);


