import React from 'react';
import { Switch, Route, withRouter } from 'react-router';
import privacy from './list/privacy';
class privacys extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/app/privacy" exact component={privacy} />
      </Switch>
    );
  }
}
export default withRouter(privacys);
