import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Alert,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import firebase from "firebase";
const db = firebase.firestore();

class CategoryNew extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new post',
    description: 'About description',
  };

  constructor(props) {
    super(props);

    this.state = {
      title: '',
    };
  }

  changetitle = (event) => {
    this.setState({title: event.target.value});
  }

  doCreatePost = async(e) => {

    const userRef2 = await db.collection('category').add({
      name: this.state.title,
  });
    if(userRef2.id)
    {
      var body={id:userRef2.id,name:this.state.title};
      axios.post('https://digittrix-staging.live/webci/foodapi/insert_cat',body, {
      headers: {
      Accept: 'multipart/form-data',
       "Content-Type": 'multipart/form-data',
       },
      }).then((res) => {
        if(res.data.status==true)
        {
          this.setState({
              title: '',
              })
               this.props.history.push('/app/category');
        }
      })
    }
    // e.preventDefault();
  }

  render() {
    return (
      <div>
        <h1>Create New Category</h1>
        <Row>
          <Col sm={6}>
              <Form >
                {this.props.message && (
                  <Alert className="alert-sm" bsstyle="info">
                    {this.props.message}
                  </Alert>
                )}
                <FormGroup>
                  <Label for="input-title">Name</Label>
                  <Input
                    id="input-title"
                    type="text"
                    placeholder="Title"
                    value={this.state.title}
                    required
                    onChange={this.changetitle.bind(this)}
                  />
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <ButtonGroup>
                    <Button color="danger" type="button" onClick={this.doCreatePost.bind(this)}>
                      {this.props.isFetching ? 'Creating...' : 'Create'}
                    </Button>
                  </ButtonGroup>
                </div>
              </Form>
          </Col>
        </Row>
      </div>
    );
  }
}



export default withRouter(CategoryNew);
