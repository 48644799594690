import React from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

import { Link } from 'react-router-dom';
// components
import PageTitle from "../../../components/PageTitle";
import Widget from "../../../components/Widget";
import Table from "../../dashboard/components/Table/Table";

// data
import mock from "../../dashboard/mock";
import firebase from "firebase";
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import moment from 'moment';
const db = firebase.firestore();

class OrderEdit extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new Customer',
    description: 'About description',
  };

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      username: '',
      phone: '',
    };
  }
  componentDidMount(){
    var id = this.props.match.params.id;
    db.collection("orders").doc(id).get().then( snapshot => {
      const dataSet = []
      snapshot.data().items.forEach(doc => {
          dataSet.push([doc.dish_name, <img src={doc.image} style={{ width: 80, borderRadius: '50%' }}/>,"€ "+Number(doc.actual_price).toFixed(2).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 }), doc.quantity]);
      });
      this.setState({payment:snapshot.data().payment,username:snapshot.data().user_name,oid:id,date:moment(new Date(snapshot.data().current_date)).format('DD/MM/YYYY'),rname:snapshot.data().restu_name,rcity:snapshot.data().restu_city,city:snapshot.data().city,
    phone:snapshot.data().phone,email:snapshot.data().email,address:snapshot.data().street+" "+snapshot.data().city+" "+snapshot.data().state+" "+snapshot.data().country,grandtotal:snapshot.data().grandtotal,shipping:snapshot.data().shipping,commition:snapshot.data().commition,discount:snapshot.data().discount});
        return dataSet;
    })
   .then(userList => { 
    this.setState({dataSet:userList});
  })
.catch(error => console.log(error))
  }



  render() {
    return (
      <>
<Link to="/app/orders" className="backbutton">
Go Back
    </Link>
        <PageTitle title={"Dish list of "+this.state.username} />
        <div className="detailpage">
          <div className="row">
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Order Id</h4>
              <p>{this.state.oid}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Data Ordine</h4>
              <p>{this.state.date}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Data/Ora Delivery</h4>
              <p>{this.state.date}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Restaurant Name</h4>
              <p>{this.state.rname}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Restaurant City</h4>
              <p>{this.state.rcity}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Customer Name</h4>
              <p>{this.state.username}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Customer Phone</h4>
              <p>{this.state.phone}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Customer Email</h4>
              <p>{this.state.email}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Customer Address</h4>
              <p>{this.state.address}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Grandtotal</h4>
              <p>{"€ "+Number(this.state.grandtotal).toFixed(2).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Earning</h4>
              <p>{"€ "+Number(this.state.commition).toFixed(2).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Discount</h4>
              <p>{"€ "+Number(this.state.discount).toFixed(2).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Shipping</h4>
              <p>{"€ "+Number(this.state.shipping).toFixed(2).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Payment Status</h4>
              <p>{this.state.payment}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Delivery Status</h4>
              <p>Pending</p>
            </div>
             
            
            
            </div>
        </div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MUIDataTable
              title=""
              data={this.state.dataSet}
              columns={["Dish Name", "Image", "Actual Price","Quantity"
            //    {
            //     label: "See Dishes",
            //     options: {
            //         customBodyRender: (value, tableMeta, updateValue) => {
            //             return (
            //                 <Link to={"/app/orders/edit/" + value} className='btn btn-primary'> View </Link>
            //             )
            //         }
            //     }
            // }
            ]}
              options={{
                filterType: "checkbox",
              }}
            />
          </Grid>
          {/*<Grid item xs={12}>
            <Widget title="Material-UI Table" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
              <Table data={mock.table} />
            </Widget>
          </Grid>*/}
        </Grid>
      </>
    );
  }
  }
  
export default withRouter(OrderEdit);
